import React from 'react';

import CommonSection from '../components/UI/CommonSection';
import Helmet from '../components/Helmet/Helmet';
import AboutSection from '../components/UI/AboutSection';
import { Container, Row, Col } from 'reactstrap';
import BecomeDriverSection from '../components/UI/BecomeDriverSection';

import driveImg from '../assets/all-images/drive.jpg';
import OurMembers from '../components/UI/OurMembers';
import '../styles/about.css';

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Boat Solutions & Lifestyle
                </h2>

                <h3 className="section__subtitle">Vision</h3>
                <p className="section__description">
                  The Company aspires to be most preferred for the marine
                  industry that meets approved quality standard in the market in
                  which we compete in East and Central Africa.
                </p>

                <h3 className="section__subtitle">Mission</h3>
                <p className="section__description">
                  The Company will provide superior quality service and make a
                  contribution to the success of the business customers and
                  communities we support. We will achieve our mission by
                  providing timely, accurate and professional service at each
                  and every customer contact.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+256 (0)784 263533 / 0701198829</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriverSection />

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
          </Row>
          <Row>
            <Col
              lg="12"
              md="6"
              sm="12"
              className="mb-5 d-flex justify-content-center gap-4"
            >
              <OurMembers />
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;

import React from 'react';
import '../../styles/become-broker.css';
import { Container, Row, Col } from 'reactstrap';

import driverImg from '../../assets/all-images/become-driver.png';
import { Link } from 'react-router-dom';

const BecomeDriverSection = () => {
  return (
    <section className="become__driver">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="become__driver-img">
            <img src={driverImg} alt="" className="w-100" />
          </Col>

          <Col lg="6" md="6" sm="12">
            <h2 className="section__title become__driver-title">
              Live a Lifestyle of Your Dreams. Own a Boat Today!
            </h2>

            <button className="btn become__driver-btn mt-4">
              {' '}
              <Link to={`/boats`}>Book Now</Link>
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BecomeDriverSection;

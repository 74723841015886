const serviceData = [
  {
    id: 1,
    title: 'Supply of Marine Materials & Products',
    icon: 'ri-ship-2-line',
    desc: `Price Index is engaged in the supply of marine materials, tools, equipments and replacement spare parts. 
            Due to our experience and close partnerships with our clients and several manufacturers we offer a wide 
            range of technical products. When it comes to logistics, Price Index doesn't see any barriers, only 
            challenges. Our international network extends through forwarders and shipping agents around the globe. 
            We can ship the desired products to your warehouse or deliver directly on board of your vessel, if necessary.`,
  },

  {
    id: 2,
    title: 'Vessel Hire & Transportation',
    icon: 'ri-sailboat-line',
    desc: `We own and operates a fleet of vessels, which are fully and officially registered under Ministry of Transport. 
           The fleet consists of Yachts, Tugs, supply boats, crew/pilot boats. Each vessel is manned by qualified and 
           experienced officers, and fully maintained by port engineers with assistance from workshop facilities. 
           This makes them efficient for hiring as well as transportation.`,
  },

  {
    id: 3,
    title: 'Mechanical Repair for Vessels & Yatchs',
    icon: 'ri-tools-line',
    desc: `Since November 2016 Price Index has been dealing in all kind of steel repair, on deck, hull, tanks, 
            hatch covers, crane sections, Cell Guides, Cargo Holds etc. All works carried by certified welders and 
            approved procedures. We also provide fully integrated in-house manufacturing and engineering solutions, 
            which include identifying regional and international offshore market needs, as well as handling projects 
            comprehensively from the initial design stage until the successful delivery of service boat.`,
  },

  {
    id: 4,
    title: 'Marine Consultancy & Survey',
    icon: 'ri-customer-service-line',
    desc: `It all starts with understanding the client's requirements. So Price Index provides total solution which matches 
            the needs. We provide our clients fully integrated solutions with innovative new concepts, ideas, and 
            engineering based on sound engineering practices.`,
  },

  {
    id: 5,
    title: 'Ship Chartering',
    icon: 'ri-ship-line',
    desc: `In addition to the chartering of our own vessels, we have extensive network of contacts in the marine 
            industry locally, regionally and internationally. The network that Price index developed with in the 
            local and international maritime industry has been very useful to be able to identify, secure and 
            provide various types of small & medium range vessels. Depending on the requirement, these have been 
            sourced out on voyage, period and time charters to customers both local and international. We under stand 
            the varying needs of customers and so have ensured to serve them independent of their scale of operations.`,
  },

  {
    id: 6,
    title: 'Ship Management',
    icon: 'ri-community-line',
    desc: `Are you looking for technical expertise without having to employ more staff members? We offer. a 
            comprehensive range of ship management services that we tailor to each vessel owner's needs. These 
            include technical and engineering services, crew training and management, new building supervision, 
            insurance, independent surveys, superintendence, audits and commercial management.We prepare and 
            implement:New Building Services on behalf of owners, Bid Analysis, Safety Management System.`,
  },
  {
    id: 6,
    title: 'Launch Services',
    icon: 'ri-direction-line',
    desc: `Price Index offer resources and vessels in order to provide efficient services at competitive rates to our 
            valuable clients. Our Services manages vessels and crew boats from its base, delivering various services 
            & catering the needs of vessels. The services offered include; Crew Transfers, Provision Delivery, Supply 
            of Spare parts and delivery among others`,
  },
  {
    id: 6,
    title: 'Electric Works for All Vessels',
    icon: 'ri-wireless-charging-line',
    desc: `Price Index supply system integration services to ship owners and operators. Our services cover the design, engineering,
installation and commissioning of electrical systems for all kinds of vessels, both new builds and conversions. We use local
personnel and content wherever possible, provide training and skills transfer, and deliver European levels of quality and workman
ship. Currently active in East Africa. We offer our services throughout the world. Dynamic management and a skilled workforce
enable us to uphold the trade marks of quality, innovation and flexibility.`,
  },
];

export default serviceData;

// import all images from assets/images directory
import img01 from '../all-images/boat offers/speedboat-offer.jpg';
import img02 from '../all-images/boat offers/epic-tide-pearl-et.jpg';
import img03 from '../all-images/boat offers/speedboat-offer-3.jpg';
import img04 from '../all-images/boat offers/speedboat-offer-5.jpg';
import img05 from '../all-images/boat offers/speedboat-offer-4.jpg';
import img06 from '../all-images/boat offers/price-index-plus.jpg';

const allBoats = [
  {
    id: 1,
    brand: 'Epic Tide',
    boatName: 'EpicTide Beach Queen ET03',
    imgUrl: img01,
    model: 'ET03',
    type: 'Speed Boat',
    category: 'Pontoon Boat',
    length: '15',
    seats: '4',
    price: '2,100',
    features: `High-performance engines, sleek and aerodynamic design, bolstered racing-style seats, hydraulic steering, 
                wakeboard tower, integrated Bluetooth sound system, LED underwater lights.`,
    description: `Beach Queen ET03 is engineered for adrenaline junkies who crave speed and excitement 
                  on the water. With its powerful engines and sporty design, this speedboat is perfect for 
                  watersports enthusiasts, thrill-seekers, and those who want to make a statement while flying 
                  across the waves`,
  },

  {
    id: 2,
    brand: 'Epic Tide',
    boatName: 'EpicTide Spedium P34',
    imgUrl: img02,
    model: 'P34',
    category: 'Panga',
    type: 'Panga Boat',
    length: '18',
    seats: '8',
    price: '8,000',
    features: `Shallow V-shaped hull, high gunwales for added safety, open deck space, forward casting platform, 
                anchor storage, built-in rod holders, cushioned seating, fish storage compartment.`,
    description: `The PangaCraft ET01 embodies the spirit of the traditional panga design, known for its 
                    exceptional stability and performance. Its shallow draft allows for navigation in both shallow 
                    and deeper waters, making it ideal for fishing, island hopping, and leisurely cruising. 
                    With a focus on functionality and versatility, this boat offers an authentic panga experience, 
                    combining heritage with modern amenities.`,
  },

  {
    id: 3,
    brand: 'Epic Tide',
    boatName: 'EpicTide AquaPedal Glide ET006',
    imgUrl: img03,
    model: 'ET006',
    category: 'Pedal',
    type: 'Pedal Boat',
    length: '14',
    seats: '4',
    price: '3,350',
    features: `Pedal-powered propeller system, adjustable seats, sun canopy for shade, built-in drink holders, 
                storage compartments, stable hull design, quiet and eco-friendly operation.`,
    description: `The AquaPedal Glide ET006 offers a unique and leisurely way to explore calm waters. 
                  Powered by the pedaling motion of the passengers, this boat provides a relaxing and eco-conscious 
                  experience for individuals, couples, or families. The adjustable seats ensure comfort during longer 
                  rides, while the sun canopy protects from the sun's rays. Whether you're gliding on a lake or a 
                  peaceful river, the AquaPedal Glide ET006 guarantees a serene and enjoyable outing.`,
  },

  {
    id: 4,
    brand: 'Epic Tide',
    boatName: 'EpicTide LakeSide Retreat ET505',
    imgUrl: img04,
    model: 'ET505',
    category: 'Motor',
    type: 'Family Pontoon',
    length: '24',
    seats: '10',
    price: '1,440',
    features: `Comfortable seating with plush cushions, removable dining table, retractable canopy for shade, built-in cooler, 
              swim ladder, Bluetooth stereo system, spacious deck space for lounging and socializing.`,
    description: `The LakeSide Retreat ET505 is designed with family fun in mind. With its spacious layout and amenities, 
                  it's the perfect vessel for creating lasting memories on the water. Whether you're hosting a barbecue, enjoying 
                  a leisurely cruise, or taking a refreshing swim, this pontoon boat offers a relaxing and enjoyable experience for 
                  everyone. Feel free to modify these boat details according to your specific boat models and features. The goal is 
                  to highlight the unique qualities of each boat type and provide potential customers with a clear understanding of 
                  what they can expect.`,
  },

  {
    id: 5,
    brand: 'Epic Tide',
    boatName: 'EpicTide Speedium P24',
    imgUrl: img05,
    model: 'P24',
    category: 'Motor',
    type: 'Speed Boat',
    length: '7.5',
    seats: '4',
    price: '5,600',
    features: `Sleek and aerodynamic design, bolstered racing-style seats, wake tower with board racks, premium sound system, 
                LED underwater lights, swim platform with ladder.`,
    description: `The Speedium ET301 is the epitome of exhilaration on the water. With its powerful engines and streamlined design, 
                  it offers a thrilling experience for water sports enthusiasts and speed aficionados. Whether you're wakeboarding, 
                  water skiing, or simply cruising, this speed boat guarantees an adrenaline rush and unmatched excitement.`,
  },

  {
    id: 6,
    brand: 'Epic Tide',
    boatName: 'EpicTide WaterTaxi 10Xpress',
    imgUrl: img06,
    model: 'ETXV',
    category: 'Water Taxi',
    type: 'Water Shuttle',
    length: '20',
    seats: '10',
    price: '4,500',
    features: `Open deck layout, cushioned seating, overhead canopy for shade, easy boarding and disembarking, efficient 
                outboard engine, life jackets and safety equipment.`,
    description: `The WaterTaxi 10Xpress offers a hassle-free and enjoyable water experience for small groups. 
                  Whether you're traveling between waterfront destinations, exploring a scenic harbor, or simply enjoying a short 
                  ride, this water taxi provides comfortable seating and swift transportation. Its compact size and maneuverability 
                  make it perfect for navigating crowded areas with ease.`,
  },
];

export default allBoats;

import React from 'react';
import Slider from 'react-slick';

import '../../styles/testimonial.css';

import ava01 from '../../assets/all-images/mike.jpg';
import ava02 from '../../assets/all-images/partner.jpg';
import ava03 from '../../assets/all-images/ava-3.jpg';
import ava04 from '../../assets/all-images/ava-4.jpg';

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          EpicTide brand brought my boating fantasies to life! Their attention
          to detail and passion for their craft truly set them apart. The boat
          they crafted for me isn't just a vessel, it's an experience. I'm left
          in awe every time I set sail.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava01} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">John Waters</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          As an avid sailor, I've been through my fair share of boats, but
          nothing compares to what I got from EpicTide. The quality, the design,
          the performance... everything is exceptional for an african maker. My
          boat has become an extension of myself, a reflection of my spirit on
          the water.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava02} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Sarah Summers</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          From the moment I saw their designs, I knew EpicTide was the right
          choice. The team's dedication to bringing my vision to life was
          outstanding. The boat not only looks amazing but handles like a dream.
          It's more than a purchase; it's an investment in unforgettable
          moments.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava03} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Duncan Atukwase</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          EpicTide didn't just build me a boat; they created a masterpiece that
          encapsulates my love for both adventure and aesthetics. Their
          professionalism and willingness to tailor every detail to my
          preferences truly impressed me. Owning one of their boats has been a
          source of pride and joy.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava04} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Mike Bugala</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;

// import images from all-images/blog-img directory
import img01 from '../all-images/blog-img/blog-1.jpg';
import img02 from '../all-images/blog-img/blog-2.jpg';
import img03 from '../all-images/blog-img/blog-3.jpg';

const blogData = [
  {
    id: 1,
    title: 'Performance Boats by Mike Rembo',
    author: 'Mike',
    date: '12 June, 2023',
    time: '9pm',
    imgUrl: img01,
    introduction:
      'This boat can deliver top speeds from the 70s to the 90s, depending on the power chosen.',
    quote: `The new Donzi 39 VRZ opens a new chapter in the history of a storied boating nameplate that has been the 
      property of Iconic Marine Group since 2017.`,
    description: `This 38-foot-8-inch performance-luxury center-console represents a brand reboot, the first in a new 
                  generation of Donzi models being developed under the guidance of a team assembled by former Cigarette 
                  Racing and Statement Marine marketing maven Craig Barrie and built at Caldwell Marine Designs in North 
                  Carolina. The point is a complete separation from the Fountain Powerboats operation at Iconic. 
                  Versatility makes center-consoles the most popular segment of the performance-boat market right now. 
                  A plush center-console is simply a great dayboat for its abundant space and the easy movement around 
                  the boat the layout affords. This Donzi will vie for boatbuyers’ dollars along with premium models like 
                  the 39-foot Nor-Tech 390 Sport ($664,395 base price with triple Mercury Racing 300R outboards) and the 
                  38-foot-7-inch Mystic M3800 ($775,000 base price with triple 300R outboards), each a luxurious 
                  12,000-pound step-hull that owners can rig with as much Mercury Racing outboard horsepower as they 
                  care to pay for. The Nor-Tech offers a “hidden fishing package,” while the Mystic makes no pretense to 
                  angling.`,
  },

  {
    id: 2,
    title: 'Repowering Fountain Hull No. 1',
    author: 'Rembo',
    date: '12 May, 2023',
    time: '9pm',
    imgUrl: img02,
    introduction: `We take a ride with living legend Reggie Fountain Jr. on his repowered hull No 1.`,
    quote:
      'This is the story of how that first boat was created, how it came back to Fountain',
    description: `Fountain owned an outboard-powered 20-foot Allison Craft runabout, a boat that could hit 90 mph on the 
        smooth waters of the Tar River thanks to its keel pad and notched transom. In the movie King of Offshore, 
        Fountain recounts how he sought to adapt the ­Allison Craft features to a larger hull suitable for bigger waters. 
        He partnered with Bill Farmer and Don Abel, custom boatbuilders from ­Sarasota, Florida, who ­modified the mold for 
        their 31-foot ­Excalibur model, originally designed by Jeane Claude Simone, to Fountain’s specifications. 
        At speed, the boat would skim along on its flat pad at the aft keel. The running surface ended short of the 
        transom to create a built-in setback for the drives, which could then mount higher to reduce drag while keeping 
        the props in solid water. Fountain called this exciting new boat the Excalibur Executioner. Fountain sold 10 or 
        12 Executioner boats, but concluded that the flat pad slapped the water in choppy conditions. He went back to 
        Farmer and Abel, and asked them to change the mold to add a modest V-angle to the pad. Having already cut up 
        their mold once for Fountain, they declined to do it again. Undeterred, Fountain went back to Washington, 
        rented an empty car-dealership building, and made his own mold by splashing one of the Executioner hulls. 
        Helping in this project was then-17-year-old Jeff Harris, a Washington high school student who worked on 
        Fountain’s racing pit crew.`,
  },

  {
    id: 3,
    title: 'Boat Launch Ramp Etiquette',
    author: 'Timothy',
    date: '12 Jul, 2023',
    time: '9pm',
    imgUrl: img03,
    description:
      ' Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.  Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.  Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.  Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.  Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.',
    quote:
      'Voluptua dolore takimata dolor sadipscing nonumy consetetur et. Stet sed dolores dolores dolores dolores eos.',
  },
];

export default blogData;

import React, { useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import { useParams } from 'react-router-dom';
import allBoats from '../assets/data/allBoats ';
import '../styles/boat-details.css';
import BookingForm from '../components/UI/BookingForm';
import PaymentMethod from '../components/UI/PaymentMethod';

const BoatDetails = () => {
  const { slug } = useParams();

  const singleBoatItem = allBoats.find((item) => item.boatName === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleBoatItem]);

  return (
    <Helmet title={singleBoatItem.boatName}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <img src={singleBoatItem.imgUrl} alt="" className="w-100" />
            </Col>

            <Col lg="6">
              <div className="boat__info">
                <h2 className="section__title">{singleBoatItem.boatName}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    ${singleBoatItem.price}
                  </h6>
                </div>

                <h6>Features</h6>
                <p className="section__description">
                  {singleBoatItem.features}
                </p>

                <h6>Description</h6>
                <p className="section__description">
                  {singleBoatItem.description}
                </p>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: '4rem' }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-sailboat-line"
                      style={{ color: '#f9a826' }}
                    ></i>{' '}
                    Model-{singleBoatItem.model}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-settings-2-line"
                      style={{ color: '#f9a826' }}
                    ></i>{' '}
                    {singleBoatItem.type}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-pencil-ruler-2-line"
                      style={{ color: '#f9a826' }}
                    ></i>{' '}
                    {singleBoatItem.length} feet
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-group-line" style={{ color: '#f9a826' }}></i>{' '}
                    {singleBoatItem.seats} seats
                  </span>
                </div>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: '2.8rem' }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-settings-6-line"
                      style={{ color: '#f9a826' }}
                    ></i>{' '}
                    {singleBoatItem.category}
                  </span>
                </div>
              </div>
            </Col>

            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold ">Booking Information</h5>
                <BookingForm />
              </div>
            </Col>

            <Col lg="5" className="mt-5">
              <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default BoatDetails;

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import HeroSlider from '../components/UI/HeroSlider';
import FindBoatForm from '../components/UI/FindBoatForm';
import AboutSection from '../components/UI/AboutSection';
import ServicesList from '../components/UI/ServicesList';
import allBoats from '../assets/data/allBoats ';
import BoatItem from '../components/UI/BoatItem';
import Testimonial from '../components/UI/Testimonial';
import BlogList from '../components/UI/BlogList';
import BecomeDriverSection from '../components/UI/BecomeDriverSection';

const Home = () => {
  return (
    <Helmet title="Home">
      {/* ============= Hero Section ============== */}
      <section className="p-0 hero__slider-section">
        <HeroSlider />

        <div className="hero__form">
          <Container>
            <Row className="form__row">
              <Col lg="4" md="4">
                <div className="find__cars-left">
                  <h2>Find your best boat here</h2>
                </div>
              </Col>

              <Col lg="8" md="8" sm="12">
                <FindBoatForm />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* =========== about section ================ */}
      <AboutSection />
      {/* ========== services section ============ */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">See our</h6>
              <h2 className="section__title">Popular Services</h2>
            </Col>

            <ServicesList />
          </Row>
        </Container>
      </section>

      {/* =========== boat offer section ============= */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h6 className="section__subtitle">Come take a pick</h6>
              <h2 className="section__title">Hot Offers</h2>
            </Col>

            {allBoats.slice(0, 6).map((item) => (
              <BoatItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>

      {/* =========== become a driver section ============ */}
      <BecomeDriverSection />

      {/* =========== testimonial section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">Our clients say...</h6>
              <h2 className="section__title">Testimonials</h2>
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>

      {/* =============== blog section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Explore our blogs</h6>
              <h2 className="section__title">Latest Blogs</h2>
            </Col>

            <BlogList />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;

import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../styles/boat-item.css';

const BoatItem = (props) => {
  const { imgUrl, model, boatName, type, seats, price } = props.item;

  return (
    <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="boat__item">
        <div className="boat__img">
          <img src={imgUrl} alt="" className="w-100" />
        </div>

        <div className="boat__item-content mt-4">
          <h4 className="section__title text-center">{boatName}</h4>
          <h6 className="rent__price text-center mt-">${price}</h6>

          <div className="boat__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className=" d-flex align-items-center gap-1">
              <i class="ri-sailboat-line"></i> Model-{model}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i class="ri-settings-2-line"></i> {type}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i class="ri-group-line"></i> {seats} seats
            </span>
          </div>

          <button className=" w-50 boat__item-btn boat__btn-rent">
            <Link to={`/boats/${boatName}`}>Book</Link>
          </button>

          <button className=" w-50 boat__item-btn boat__btn-details">
            <Link to={`/boats/${boatName}`}>Details</Link>
          </button>
        </div>
      </div>
    </Col>
  );
};

export default BoatItem;
